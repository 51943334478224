<template>
  <div>
    <div v-if="!addVisible && !detailVisible">
      <div
        class="card"
        style="display: flex"
        :style="{ height: height, overflowY: 'hidden' }"
      >
        <div class="left">
          <el-button style="height:40px;" type="primary" @click="handleAdd">新增金料入库</el-button>
          <el-button style="height:40px;" type="primary" @click="handlePrintAll">打印</el-button>
          <el-button style="height:40px;" type='primary' plain @click='handleExport' icon='el-icon-upload2' :loading="exportLoading">导出</el-button>
        </div>
        <div class="line"></div>
        <div class="middle">
          <el-form :model="form" :inline="true">
            <el-form-item label="入库单号">
              <el-input
                placeholder="请输入入库单号"
                v-model="form.material_entry_number"
                @keydown.enter.native="getList"
              ></el-input>
            </el-form-item>
            <el-form-item label="材质成色">
              <el-select
                v-model="form.attr_value_id"
                placeholder="请选择"
                filterable
                clearable
              >
                <template v-for="item in materialList">
                  <el-option
                    :label="item.attr_value"
                    :value="item.attr_value_id"
                    :key="item.attr_value_id"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="审核状态">
              <el-select v-model="form.status" placeholder="请选择审核状态">
                <el-option label="全部" :value="0"></el-option>
                <el-option label="待审核" :value="10"></el-option>
                <el-option label="审核通过" :value="30"></el-option>
                <el-option label="审核不通过" :value="20"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="创建人">
              <el-select
                v-model="form.create_store_user_id"
                placeholder="请选择"
                filterable
                clearable
              >
                <template v-for="item in userList">
                  <el-option
                    :label="item.realname"
                    :value="item.store_user_id"
                    :key="item.store_user_id"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker
                v-model="form.create_time"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="审核人">
              <el-select
                v-model="form.audit_store_user_id"
                placeholder="请选择"
                filterable
                clearable
              >
                <template v-for="item in userList">
                  <el-option
                    :label="item.realname"
                    :value="item.store_user_id"
                    :key="item.store_user_id"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="审核时间">
              <el-date-picker
                v-model="form.audit_time"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div style="margin: 0 10px 0 auto">
            <el-button
              @click="handleHeight"
              style="
                width: 40px;
                height: 40px;
                line-height: 36px;
                text-align: center;
                padding: 0;
              "
            >
              <i class="el-icon-caret-bottom" v-if="height === '40px'"></i>
              <i class="el-icon-caret-top" v-else></i>
            </el-button>
          </div>
          <div style="display: flex">
            <el-button type="primary" @click="onSubmit" style="height: 40px"
              >搜索</el-button
            >
            <el-button @click="resetForm" style="height: 40px">重置</el-button>
          </div>
        </div>
      </div>
      <div class="table">
        <el-table
          :data="tableData.list"
          style="width: 100%"
          height="720"
          stripe
          v-loading="loading"
          border
          @row-dblclick="handleDalclick"
          :row-class-name="picRowClassName"
        >
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias === 'attr_value'">
                  <div
                    v-for="(item, index) in scope.row.goods"
                    :key="'goods' + index"
                    class="cell"
                    :class="index + 1 === scope.row.goods.length ? '' : 'border'"
                  >
                    {{ item.attr_value }}
                  </div>
                </template>
                <template v-else-if="item.field_alias === 'gold_weight'">
                  <div
                    v-for="(item, index) in scope.row.goods"
                    :key="'goods' + index"
                    class="cell"
                    :class="index + 1 === scope.row.goods.length ? '' : 'border'"
                  >
                    {{ item.gold_weight }}
                  </div>
                </template>
                <template v-else-if="item.field_alias === 'unit_price'">
                  <div
                    v-for="(item, index) in scope.row.goods"
                    :key="'goods' + index"
                    class="cell"
                    :class="index + 1 === scope.row.goods.length ? '' : 'border'"
                  >
                    {{ item.unit_price }}
                  </div>
                </template>
                <template v-else-if="item.field_alias === 'amount_money'">
                  <div
                    v-for="(item, index) in scope.row.goods"
                    :key="'goods' + index"
                    class="cell"
                    :class="index + 1 === scope.row.goods.length ? '' : 'border'"
                  >
                    {{ item.amount_money }}
                  </div>
                </template>
                <template v-else>
                  <div style="padding: 0 10px">
                    {{
                      scope.row[item.field_alias]
                        ? scope.row[item.field_alias]
                        : "--"
                    }}
                  </div>
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column label="操作" align="center" width="180px">
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option
                    label="审核"
                    :value="1"
                    v-if="scope.row.status === 10"
                  ></el-option>
                  <el-option label="详情" :value="2"></el-option>
                  <el-option
                    label="编辑"
                    :value="3"
                    v-if="
                      noSockIds.includes(scope.row.material_entry_id) ||
                      scope.row.status === 20
                    "
                  ></el-option>
                  <el-option
                    label="删除"
                    :value="4"
                    v-if="
                      noSockIds.includes(scope.row.material_entry_id) ||
                      scope.row.status === 20
                    "
                  ></el-option>
                  <el-option
                    label="解锁"
                    :value="5"
                    v-if="scope.row.status === 30 && !noSockIds.includes(scope.row.material_entry_id)"
                  ></el-option>
                  <el-option label="打印" :value="6" ></el-option>
                  <el-option label="导出" :value="7" ></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          :current-page="page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="$store.state.pageSizes"
          :page-size="$store.state.pageSizes[0]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total"
        >
        </el-pagination>
      </div>
    </div>
    <Add v-if="addVisible" @onBack="handleBack" :row="currRow" :type="type" />
    <Detail v-if="detailVisible" :row="currRow" :type="type" @onBack="handleBack" />
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
  </div>
</template>

<script>
import {
  handleBackDeleteReq,
  handleDeleteReq,
  getMaterialListReq,
  getListReq,
  handleAuditReq,
  exportReq,
  exportDetailReq,
  getDetailReq,
} from "@/api/goods/raw/in/index";

import { ListRequest } from "@/api/authority/menage";
import { getIsLock } from "@/api/monthSettle";
import Add from "./Add.vue";
import Detail from "./Detail.vue";
import LocalPrint from "@/components/localPrint/Index.vue";

export default {
  data() {
    return {
      title: "",
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      allRows: [
        {
          field: "index",
          field_alias: "index",
          field_text: "序号",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
        },
        {
          field: "material_entry_number",
          field_alias: "material_entry_number",
          field_text: "入库单号",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
        },
        {
          field: "attr_value",
          field_alias: "attr_value",
          field_text: "材质成色",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
          is_goods: true,
        },
        {
          field: "gold_weight",
          field_alias: "gold_weight",
          field_text: "净金重",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
          is_goods: true,
        },
        {
          field: "unit_price",
          field_alias: "unit_price",
          field_text: "单价",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
          is_goods: true,
        },
        {
          field: "amount_money",
          field_alias: "amount_money",
          field_text: "金额",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
          is_goods: true,
        },
        {
          field: "total_amount_money",
          field_alias: "total_amount_money",
          field_text: "总金额",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
        },
        {
          field: "status_text",
          field_alias: "status_text",
          field_text: "状态",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
        },
        {
          field: "add_realname",
          field_alias: "add_realname",
          field_text: "创建人",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
        },
        {
          field: "create_time",
          field_alias: "create_time",
          field_text: "创建时间",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
        },
        {
          field: "audit_realname",
          field_alias: "audit_realname",
          field_text: "审核人",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
        },
        {
          field: "audit_time",
          field_alias: "audit_time",
          field_text: "审核时间",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 17.5,
        },
      ],
      detailRows: [
        {
          field: "index",
          field_alias: "index",
          field_text: "序号",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
        {
          field: "attr_value",
          field_alias: "attr_value",
          field_text: "材质成色",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
        {
          field: "gold_weight",
          field_alias: "gold_weight",
          field_text: "重量",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
        {
          field: "unit_price",
          field_alias: "unit_price",
          field_text: "单价",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
        {
          field: "amount_money",
          field_alias: "amount_money",
          field_text: "金额",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
        {
          field: "remark",
          field_alias: "remark",
          field_text: "备注",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
      ],
      exportLoading: false,
      detailVisible: false,
      noSockIds: [],
      type: "add",
      currRow: {},
      userList: [],
      addVisible: false,
      loading: false,
      form: {},
      materialList: [],
      offsetHeight: "106px",
      height: "40px",
      rowList: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "入库单号", field_alias: "material_entry_number" },
        { field_text: "材质成色", field_alias: "attr_value" },
        { field_text: "净金重", field_alias: "gold_weight" },
        { field_text: "单价", field_alias: "unit_price" },
        { field_text: "金额", field_alias: "amount_money" },
        { field_text: "总金额", field_alias: "total_amount_money" },
        { field_text: "状态", field_alias: "status_text" },
        { field_text: "创建人", field_alias: "add_realname" },
        { field_text: "创建时间", field_alias: "create_time" },
        { field_text: "审核人", field_alias: "audit_realname" },
        { field_text: "审核时间", field_alias: "audit_time" },
      ],
      tableData: {
        list: [{}],
        total: 0,
      },
      page: 1,
      limit: this.$store.state.pageSizes[0],
    };
  },
  components: {
    Add,
    Detail,
    LocalPrint,
  },
  created() {
    this.getMaterialList();
    this.getUserList();
    this.getList();
  },
  methods: {
    // 打印全部
    handlePrintAll() {
      if (!this.tableData.list.length) {
        this.$message.warning("当前没有数据可以打印");
        return;
      }
      this.title = '金料入库单';
      this.printRows = this.allRows;
      this.tableData.list.forEach((item) => {
        item.rowspan = item.goods.length;
      })
      this.printData.list = this.tableData.list;
      this.localPrintVisible = true;
      console.log(this.printData.list);
    },
    // 导出
    handleExport() {
      this.exportLoading = true;
      exportReq({ ...this.form, page: this.page, limit: this.limit, export: 2 })
        .then((res) => {
          this.exportLoading = false;
          let blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `金料入库单列表.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    // 修改状态
    handleAudit(status) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          handleAuditReq({
            material_entry_id: this.currRow.material_entry_id,
            status,
          })
            .then((res) => {
              if (res.code === 1) {
                this.$message.success("操作成功");
                this.getList();
              }
            })
            .catch(() => {
              this.currRow.select = "";
            });
        })
        .catch(() => {});
    },
    handleAdd() {
      this.type = "add";
      this.addVisible = true;
    },
    handleChange(e, row) {
      this.currRow = row;
      // 审核
      if (e === 1) {
        this.type = "examine";
        // this.addVisible = true;
        this.detailVisible = true;
      }
      // 详情
      if (e === 2) {
        this.type = "detail";
        // this.addVisible = true;
        this.detailVisible = true;
      }
      // 编辑
      if (e === 3) {
        this.type = "edit";
        this.addVisible = true;
      }
      // 删除
      if (e === 4) {
        // 驳回删除
        if (row.status === 20) {
          this.$confirm("确定要删除吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            handleDeleteReq({
              material_entry_id: row.material_entry_id,
              status: 999,
            }).then((res) => {
              if (res.code === 1) {
                this.$message.success("删除成功");
                this.getList();
              }
            });
          })
            .catch(() => {
              row.select = '';
            });
        }
        // 反确认删除
        if (row.status === 30) {
          this.$confirm("确定要删除吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            handleBackDeleteReq({
              material_entry_id: row.material_entry_id,
              status: 999,
            }).then((res) => {
              if (res.code === 1) {
                this.$message.success("删除成功");
                row.select = "";
                this.getList();
              }
            })
              .catch(() => {
                row.select = "";
              });
          }).catch(() => {
            row.select = '';
          });
        }
      }
      // 解锁
      if (e === 5) {
        getIsLock({
          source: "storagegold",
          primary_key_id: row.material_entry_id,
        })
          .then((res) => {
            if (res.code === 1) {
              // this.addVisiable = true;
              row.select = "";
              this.noSockIds.push(row.material_entry_id);
              this.$message.success("解锁成功");
            }
          })
          .catch(() => {
            row.select = "";
          });
      }
      // 打印
      if (e === 6) {
        getDetailReq({
          material_entry_id: row.material_entry_id,
        })
          .then((res) => {
            row.select = '';
            if (res.code === 1) {
              this.title = '金料入库单详情';
              this.printData.top = [];
              const arr1 = [
                { txt: "入库单号", value: res.data.material_entry_number, width: "24%" },
                { txt: "供应商", value: res.data.supplier_name, width: "24%" },
                { txt: "备注", value: res.data.remark, width: "24%" },
              ];
              this.printData.top.push(arr1);
              this.printRows = this.detailRows;
              res.data.goods.forEach((item, index) => {
                item.index = index + 1;
              });
              this.printData.list = res.data.goods;
              this.localPrintVisible = true;
            }
          })
          .catch(() => {
            row.select = '';
          });
      }
      // 导出
      if (e === 7) {
        exportDetailReq({
          material_entry_id: row.material_entry_id,
        })
        .then((res) => {
            row.select = '';
            let blob = new Blob([res], { type: "application/vnd.ms-excel" });
            const a = document.createElement("a");
            a.setAttribute("download", `金料入库单详情.xlsx`);
            a.href = URL.createObjectURL(blob);
            a.click();
        })
        .catch(() => {
          row.select = '';
        });
      }

    },
    handleBack(getList) {
      if (getList) {
        this.getList();
      }
      this.addVisible = false;
      this.detailVisible = false;
      this.currRow.select = "";
      this.currRow = {};
    },
    picRowClassName({ row, rowIndex }) {
      // 给每一行添加索引
      row.index = rowIndex + 1;
    },
    // 获取列表
    getList() {
      this.loading = true;
      getListReq({
        page: this.page,
        limit: this.limit,
        ...this.form,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取员工列表
    getUserList() {
      ListRequest().then((res) => {
        if (res.code === 1) {
          this.userList = res.data.list;
        }
      });
    },
    // 获取材质成色列表
    getMaterialList() {
      getMaterialListReq().then((res) => {
        if (res.code === 1) {
          this.materialList = res.data;
        }
      });
    },
    handleDalclick(row) {
      this.type = "detail";
      this.currRow = row;
      // this.addVisible = true;
      this.detailVisible = true;
    },
    handleHeight() {
      this.height = this.height === "40px" ? this.offsetHeight : "40px";
    },
    onSubmit() {
      this.getList();
    },
    resetForm() {
      this.form = {};
      this.getList();
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.left {
  display: flex;
}
/deep/ .el-table__row .cell {
  padding: 0;
}
.border {
  border-bottom: 1px solid #ddd;
}
.cell {
  line-height: 40px;
  padding: 0 10px;
}
.card {
  transition: all 0.3s;
}
.middle {
  display: flex;
}
</style>
