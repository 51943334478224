import { post } from '@/utils/request';
// 详情导出
const exportDetailReq = (data) => post({
  url: '/store/material.MaterialEntry/getGemAllDownload',
  responseType: 'blob',
  data: {
    ...data,
  },
})

// 导出
const exportReq = (data) => post({
  url: '/store/material.MaterialEntry/getListDownload',
  responseType: 'blob',
  data: {
    ...data,
  },
})

// 材质成色列表
const getMaterialListReq = () => post({
  url: '/store/common.Select/getMaterialList',
});

// 金料入库
const createDataReq = (params) => post({
  url: '/store/material.MaterialEntry/createData',
  data: {
    ...params,
  },
});

// 金料列表
const getListReq = (params) => post({
  url: '/store/material.MaterialEntry/getList',
  data: {
    ...params,
  },
});

// 状态修改
const handleAuditReq = (params) => post({
  url: '/store/material.MaterialEntry/handleAudit',
  data: {
    ...params,
  },
});

// 金料入库详情
const getDetailReq = (params) => post({
  url: '/store/material.MaterialEntry/getGemAll',
  data: {
    ...params,
  },
});

// 金料入库编辑（驳回）
const updateDateReq = (params) => post({
  url: '/store/material.MaterialEntry/updateDate',
  data: {
    ...params,
  },
});

// 金料入库编辑（反确认）
const backUpdateDateReq = (params) => post({
  url: '/store/material.MaterialEntry/backUpdateDate',
  data: {
    ...params,
  },
});

// 金料入库（不通过删除）
const handleDeleteReq = (params) => post({
  url: '/store/material.MaterialEntry/handleDelete',
  data: {
    ...params,
  },
});

// 反确认删除
const handleBackDeleteReq = (params) => post({
  url: '/store/material.MaterialEntry/handleBackDelete',
  data: {
    ...params,
  },
});

export {
  exportDetailReq,
  exportReq,
  handleBackDeleteReq,
  handleDeleteReq,
  backUpdateDateReq,
  updateDateReq,
  getDetailReq,
  handleAuditReq,
  getListReq,
  getMaterialListReq,
  createDataReq,
};
